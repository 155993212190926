<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditLiveSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Editar live
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <img
            :src="form.imgOriginal"
            style="max-width: 150px"
          >
          <!-- Imagem -->
          <validation-provider
            v-slot="{ errors }"
            name="image"
          >
            <b-form-group
              label="Capa "
              label-for="image"
            >
              <b-form-file
                id="image"
                v-model="img"
                placeholder="Selecione a capa..."
                drop-placeholder="Arraste seu arquivo para aqui..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Titulo -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Titulo"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Tipo do Link -->
          <validation-provider
            v-slot="{ errors }"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Tipo do link"
              label-for="type"
            >
              <v-select
                id="type"
                v-model="form.type"
                :options="type_link"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Link -->
          <validation-provider
            v-slot="{ errors }"
            name="url"
            rules="required"
          >
            <b-form-group
              label="url"
              label-for="url"
            >
              <b-form-input
                id="url"
                v-model="form.url"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- DAta -->
          <validation-provider
            v-slot="{ errors }"
            name="date"
            rules="required"
          >
            <b-form-group
              label="Data"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                v-model="form.date"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- inicio do tempo -->
          <validation-provider
            v-slot="{ errors }"
            name="start_time"
            rules="required"
          >
            <b-form-group
              label="Inicio"
              label-for="start_time"
            >
              <b-form-timepicker
                v-model="form.time"
                locale="pt-br"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CRM Required -->
          <validation-provider
            v-slot="{ errors }"
            name="crm_required"
            rules="required"
          >
            <b-form-group
              label="CRM OBRIGATORIO"
              label-for="crm_required"
            >
              <v-select
                id="crm_required "
                v-model="form.crm_required"
                :options="boolean"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Ready -->
          <validation-provider
            v-slot="{ errors }"
            name="ready"
            rules="required"
          >
            <b-form-group
              label="Pronto para iniciar"
              label-for="ready"
            >
              <v-select
                id="ready "
                v-model="form.ready"
                :options="boolean"
                label="Tipo"
                placeholder="Selecione a opção"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Atualizar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import LivesServices from './LivesServices'
// eslint-disable-next-line import/no-unresolved
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-unresolved
import { required, alphaNum, email } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
// import countries from '@/@fake-db/data/other/countries'
import { mapActions } from 'vuex'
import { integer } from 'vee-validate/dist/rules'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditLiveSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // countries,
      speaker_id: null,
      event_id: null,
      password_vmix: null,
      path: null,
      events: [],
      speakers: [],
      boolean: ['Não', 'Sim'],
      typeEvent: ['Palestra', 'Mesa redonda'],
      level: ['Facil', 'Intermediario', 'Avançado'],
      language: ['Portugues', 'Ingles'],
      room: ['Sala 01', 'Sala 02'],
      type_link: ['Youtube', 'Cloudflare', 'Vimeo'],
      form: {
        img: [],
        imgOriginal: '',
        title: '',
        type: '',
        url: '',
        date: '',
        time: '',
        crm_required: '',
        ready: '',
      },
      img: [],
    }
  },
  computed: {
    eventsState() {
      return this.$store.state.congressAdministrator.events
    },
    speakersState() {
      return this.$store.state.congressAdministrator.speakers
    }
  },
  watch: {},
  mounted() {
    this.path = process.env.VUE_APP_API_BASE
    this.form.title = this.data.title
    this.form.type = this.data.type
    this.form.url = this.data.url
    this.form.date = this.data.date
    this.form.time = this.data.time
    this.form.crm_required = this.data.crm_required
    this.form.ready = this.data.ready
    this.form.imgOriginal = `${this.path}/storage/live/image/${this.data.img}`
    console.log(this.form)
  },
  created() {
  },
  methods: {
    resetForm() {
      this.form = {}
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            // form data is needed because image file
            const formData = new FormData()
            const imagefile = document.querySelector('#image')
            formData.append('id', this.data.id)
            console.log(this.img)
            if (this.img.length) {
              formData.append('img', imagefile.files[0])
              console.log('entrou')
            }
            formData.append('title', this.form.title)
            formData.append('type', this.form.type)
            formData.append('url', this.form.url)
            formData.append('date', this.form.date)
            formData.append('time', this.form.time)
            formData.append('crm_required', this.form.crm)
            formData.append('ready', this.form.ready === 'Sim' ? 1 : 0)
            // formData.append('...', this.form...)

            // Call to create request
            await LivesServices().liveEdit(formData).then(api => {
              if (api.data.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: api.data.message,
                    variant: 'danger',
                  }
                })
              } else if (api.data.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
              }
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: err.message,
                  variant: 'danger',
                }
              })
            })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
